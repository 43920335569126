.container {
    border: none !important;
}

.boxshadow-bottom {
    margin-top: 10px;
    box-shadow: 0px 3px 6px #00000014;
    margin-bottom: 10px;
    min-height: 90px;;
    line-height: 90px;
}

.companyLogo {
    width: auto;
    max-height: 60px;
}

.banner-text {
    text-align: center;
    letter-spacing: 0px;
    color: #000000;
    font-size: 22px;
    font-family: 'RobotoBold';
}